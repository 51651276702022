<template>
  <div>
    <el-card>
      <div>
        <el-button type="primary" size="medium" class="demo-input-label" icon="el-icon-search" @click="searchClick">搜索</el-button>
        <el-button type="primary" size="medium" class="demo-input-label" icon="el-icon-plus" @click="addInfoClick()">添加</el-button>
      </div>
    </el-card>
    <div>
      <el-table :data="records" border style="width: 98%" :height="innerHeight">
        <el-table-column type="expand">
          <template #default="scope">
            <el-descriptions title="补充信息" style="padding: 0 20px">
              <el-descriptions-item label="描述信息">{{ scope.row.detailIntroduce }}</el-descriptions-item>
              <el-descriptions-item label="题目数量">{{ scope.row.hiqNum }}</el-descriptions-item>
              <el-descriptions-item label="每日题目数量">{{ scope.row.hiqDayNum }}</el-descriptions-item>
              <el-descriptions-item label="达标天数">{{ scope.row.reachStandardDay }}</el-descriptions-item>
              <el-descriptions-item label="描述信息">{{ scope.row.detailIntroduce }}</el-descriptions-item>
              <el-descriptions-item label="简介" v-if="scope.row.description">{{ scope.row.description }}</el-descriptions-item>
              <el-descriptions-item label="排行榜">{{ scope.row.needRank == 1 ? '包含' : '不包含' }}</el-descriptions-item>
              <el-descriptions-item label="详情-专业背书">{{ scope.row.detailEndorsement }}</el-descriptions-item>
              <el-descriptions-item label="专业背书图" v-if="scope.row.detailEndorsementImg">
                <el-image style="width: 100px; height: 100px" :src="scope.row.detailEndorsementImg" :fit="fit"></el-image>
              </el-descriptions-item>
              <el-descriptions-item label="banner图" v-if="scope.row.imgBanner">
                <el-image style="width: 100px; height: 100px" :src="scope.row.imgBanner" :fit="fit"></el-image>
              </el-descriptions-item>
              <el-descriptions-item label="海报图" v-if="scope.row.imgBg">
                <el-image style="width: 100px; height: 100px" :src="scope.row.imgBg" :fit="fit"></el-image>
              </el-descriptions-item>
              <el-descriptions-item label="模板图" v-if="scope.row.imgIcon">
                <el-image style="width: 100px; height: 100px" :src="scope.row.imgIcon" :fit="fit"></el-image>
              </el-descriptions-item>
            </el-descriptions>
          </template>
        </el-table-column>
        <el-table-column type="index" width="50">
          <template slot="header" slot-scope="{}">序号</template>
        </el-table-column>
        <el-table-column prop="mtName" label="活动模板名称" width="150" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="tenantName" label="公司名称" width="150" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="type" label="活动类型" width="150" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="createTime" label="创建时间" :show-overflow-tooltip="true"></el-table-column>

        <el-table-column label="操作" width="300">
          <template slot-scope="scope">
            <el-button type="primary" icon="el-icon-edit" size="mini" @click="updateClick(scope.row)">编辑</el-button>
            <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteInfoClick(scope.row.id)">删除</el-button>
            <el-button type="primary" icon="el-icon-edit" size="mini" @click="examineInfoClick(scope.row.id)">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <pagination :pagingParams="params" :total="total" @pagingSizeChange="handleSizeChange" @pagingNumChange="handleCurrentChange"></pagination>
    </div>
    <!-- 新增模式模板、修改模式模板 -->
    <el-dialog :title="dialogType != 2 ? '修改模式模板' : '新增模式模板'" :visible.sync="isDialog" v-if="isDialog" width="50%" @close="isDialog = false">
      <el-form :model="saveInfoParams" :rules="rules" ref="editFormRef" label-width="120px">
        <el-form-item label="模板名称" prop="mtName">
          <el-input v-model="saveInfoParams.mtName" placeholder="请输入模板名称"></el-input>
        </el-form-item>
        <el-form-item label="模板类型" prop="type">
          <el-input v-model="saveInfoParams.type" placeholder="请输入模板类型" type="number"></el-input>
        </el-form-item>
        <el-form-item label="描述信息">
          <el-input v-model="saveInfoParams.detailIntroduce" placeholder="description"></el-input>
        </el-form-item>
        <el-form-item label="题目数量">
          <el-input v-model="saveInfoParams.hiqNum" placeholder="description" type="Number"></el-input>
        </el-form-item>
        <el-form-item label="每日题目数量">
          <el-input v-model="saveInfoParams.hiqDayNum" placeholder="description" type="Number"></el-input>
        </el-form-item>
        <el-form-item label="达标天数">
          <el-input v-model="saveInfoParams.reachStandardDay" placeholder="description" type="Number"></el-input>
        </el-form-item>
        <el-form-item label="是否包含排行榜">
          <el-input v-model="saveInfoParams.needRank" placeholder="description" type="Number"></el-input>
        </el-form-item>
        <el-form-item label="简介">
          <el-input v-model="saveInfoParams.description" placeholder="description"></el-input>
        </el-form-item>
        <el-form-item label="详情-专业背书">
          <el-input v-model="saveInfoParams.detailEndorsement" placeholder="detailEndorsement"></el-input>
        </el-form-item>
        <el-form-item label="专业背书图片">
          <el-input v-model="saveInfoParams.detailEndorsementImg" placeholder="detailEndorsementImg"></el-input>
        </el-form-item>
        <el-form-item label="banner图">
          <uploadImg @imgChage="imgChage" name="imgBanner" :imgUrl="saveInfoParams.imgBanner"></uploadImg>
        </el-form-item>
        <el-form-item label="海报图">
          <uploadImg @imgChage="imgChage" name="imgBg" :imgUrl="saveInfoParams.imgBg"></uploadImg>
        </el-form-item>
        <el-form-item label="模板图">
          <uploadImg @imgChage="imgChage" name="imgIcon" :imgUrl="saveInfoParams.imgIcon"></uploadImg>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isDialog = false">取 消</el-button>
        <el-button type="primary" @click="submitFrom">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import pagination from '@/components/common/pagination/Index.vue'
import uploadImg from '@/components/common/uploadImg/Index.vue'
import { commonMixin } from '@/utils/mixin/index.js'

const saveInfoParams = {
  mtName: '',
  type: '4',
  imgIcon: '',
  description: '',
  status: 1,
  imgBg: '',
  imgBanner: '',
  detailIntroduce: '',
  hiqNum: '',
  hiqDayNum: '',
  imgIcon: '',
  needRank: '',
  reachStandardDay: '',
}
export default {
  name: 'AdminDemoIndex',
  components: {
    pagination,
    uploadImg,
  },
  mixins: [commonMixin],
  data() {
    return {
      params: { pageNum: 1, pageSize: 10 },
      isDialog: false,
      saveInfoParams: {
        mtName: '',
        type: '',
        imgIcon: '',
        description: '',
        status: 1,
        imgBg: '',
        imgBanner: '',
        detailIntroduce: '',
        hiqNum: '',
        hiqDayNum: '',
        imgIcon: '',
        needRank: '',
        reachStandardDay: '',
      },
      rules: {
        mtName: [
          { required: true, message: '请输入活动名称', trigger: 'blur' },
          {
            min: 2,
            max: 20,
            message: '长度在 2 到 20 个字符',
            trigger: 'blur',
          },
        ],
        type: [
          {
            required: true,
            message: '请输入type类型',
            trigger: 'blur',
          },
        ],
      },
      records: [],
      total: 0,
      dialogType: 1,
    }
  },
  mounted() {
    this.findList()
  },
  methods: {
    imgChage(item) {
      this.saveInfoParams[item.name] = item.url
    },
    async findList() {
      const res = await this.$API.modelTemplate.get(this.params)
      if (res.status !== 200) return this.$message.error(res.msg)
      const { records, total } = res.data
      this.records = records
      this.total = +total
    },
    // 添加
    addInfoClick() {
      this.isDialog = true
      this.dialogType = 1
      this.saveInfoParams = saveInfoParams
    },
    updateClick(item) {
      this.saveInfoParams = item
      this.isDialog = true
      this.dialogType = 2
    },

    // 重置from
    resetFrom(msg) {
      msg && this.$message.success(msg)
      this.saveInfoParams = saveInfoParams
      this.$refs.editFormRef.resetFields()
      this.isDialog = false
    },
    submitFrom() {
      this.$refs.editFormRef.validate(async (valid) => {
        if (valid) {
          this.dialogType == 1 && this.addInfo()
          this.dialogType == 2 && this.updateInfo()
        } else {
          return this.$message.error('必填项不能为空')
        }
      })
    },
    async updateInfo() {
      const res = await this.$API.modelTemplate.update(this.saveInfoParams)
      if (res.status !== 200) return this.$message.error(res.msg)
      this.resetFrom(res.msg)
    },
    async addInfo() {
      const res = await this.$API.modelTemplate.add(this.saveInfoParams)
      if (res.status !== 200) return this.$message.error(res.msg)
      this.$message.success(res.data)
      this.resetFrom(res.msg)
      this.params.pageNum = 1
      this.findList()
    },
    async deleteInfoClick(id) {
      const isTrue = await this.deleteClick()
      if (!isTrue) return ''
      this.deleteInfo(id)
      this.findList()
    },
    async deleteInfo(id) {
      const res = await this.$API.modelTemplate.delete({ id })
      if (res.status !== 200) return this.$message.error(res.msg)
      this.$message.success(res.data)
      this.findList()
    },
  },
}
</script>

<style lang="scss" scoped>
.healthIQ {
  width: 100%;
  background: #fff;
}

.el-cascader {
  width: 100%;
}

.pagination {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.el-breadcrumb {
  margin-bottom: 20px;
}
.demo-input-label {
  display: inline-block;
  width: 130px;
  margin-right: 10px;
  margin-left: 0;
}
::v-deep.el-tree-node {
  .is-leaf + .el-checkbox .el-checkbox__inner {
    display: inline-block;
  }
  .el-checkbox .el-checkbox__inner {
    display: none;
  }
}
.el-pagination {
  width: 1000px;
  margin: 20px auto;
}
.el-tag {
  margin: 7px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
